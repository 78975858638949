/* Force Material and DevExtreme to inherit our font choice rather than defining their own. */
.mat-card,.mat-stepper-horizontal,.mat-expansion-panel-header,
.mat-expansion-panel-body,.mat-expansion-panel-content,
.mat-tab-group,.mat-tab-label,.mat-button-wrapper,.mat-stroked-button,
.mat-tab-link,.mat-form-field,.mat-menu-item,.mat-select-value
.mat-button-toggle,.mat-checkbox,.collaboration-chart-text {
    font-family: 'Eptura Flexible', sans-serif;
}

.dx-widget,.dx-overlay-wrapper, .dx-widget input,
.dx-selectbox .dx-texteditor-input:read-only,
.dx-dropdowneditor-button-visible .dx-texteditor-input, 
.dx-searchbox .dx-texteditor-input, .dx-overlay-wrapper textarea, .dx-overlay-wrapper input {
    font-family: 'Eptura Flexible', sans-serif;
}
/* Font inheritance section complete. */

.dx-scheduler-date-table-row:nth-child(even) {
    background-color: #D1D3D3;
}

.dx-datagrid .dx-row-alt td {
    background-color: lightgray;
}

.dx-datagrid .dx-texteditor-container {
    height: 35px;
}

.dx-datagrid .dx-icon-search {
    display: none;
}

.dx-header-row {
    background-color: #0F2027;
    color: white;
}

.dx-header-row > td {
    border-left: 1px solid #0F2027 !important;
    border-right: 1px solid #0F2027 !important;
}

.dx-command-select {
    width: 50px;
    min-width: 50px;
}

.dx-button-content > .dx-icon-close {
    color: #404861;
}

.dx-header-filter {
    color: #ffab4c !important;
}

.dx-header-filter-empty {
    color: grey !important;
}

.dx-dropdownmenu-popup .dx-toolbar-menu-section .dx-toolbar-item-auto-hide {
    padding: 0;
}

.navbar-nav {
    margin-left: 5px !important;
    float: left !important;
}

@media (max-width: 749px) {
    .dx-icon-clear {
        margin-left: -16px !important;
    }
}

.glyphicon-chevron-down {
    margin-left: 4px;
}

.dx-group-row {
    background-color: #eeeeee !important
}

.dx-row-inserted .dx-highlight-outline::after {
    border: none !important;
}

.dx-row-inserted {
    background-color: #bbdce5 !important;
}

.dx-row-removed > td {
    background-color: #f77d61 !important;
    font-style: italic;
}

/* workaround to fix apparent devextreme compatibility issue with Angular 7 (see R2-756) */
.dx-scheduler-work-space {
    margin-top:0px !important;
}

.dx-scheduler-appointment-tooltip-wrapper .dx-popup-content {
    border-left: solid 5px #337ab7;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

/* See Core-1051. Workaround for Exago-injected CSS, which sets .row to display: flex */
:not(#exagomastercontainer) .row {
    display: block;
}

/* See WO-260. Another Exago workaround, this time for .panel, which was getting display: none */
:not(#exagomastercontainer) .panel {
    display: block;
    position: static; /* This is why dashboard.component.css@.panel-primary needs !important. */
    width: auto;
    background-color: #fff;
}

head+body, :root {
    position: static;
}

/* Override DevExtreme's default border in work order list (small screens). */
.mainRouteContainer-sm-condensed work-order-list .dx-list-item {
    border: none;
}

/* Override DevExtreme's default border in asset list (small screens). */
.mainRouteContainer-sm-condensed asset-list .dx-list-item {
    border: none;
}

.dx-overlay-shader {
    background-color: rgba(128, 128, 128, 0.5);
}

/* overriding icon for column chooser button in DevExtreme grids */
.dx-icon-column-chooser {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    font-style: normal;
    font-variant: normal;
}

    .dx-icon-column-chooser:before {
        content: "\e411";
    }
